import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/ckeckp.jpg'

const Generlchp = () => {
    return (
        <>

            <div className='heading'>
                <h1>Home / General Checkups, Psychiatry </h1>
            </div>

            <div className='general'>
                <div className='general2'>
                    <h1>General Checkup, Psychiatry</h1>
                    <p>A general psychiatric evaluation is ordered when there's suspicion that you have a mental health condition. During the assessment, your medical and family history will be reviewed. A person's family
                        history is relevant with many mental health conditions as some disorders are passed down through genes.</p>

                    <p>The most commonly used personality tests are the Rorschach, TAT, and MMPI. The assumptions underlying projective tests such as the Rorschach and TAT are that the standard set of stimuli are used as a screen to project material that cannot be obtained through a more structured approach.</p>


                </div>
                <div className='general2'>
                    <img src={gal1} />
                </div>
            </div>


            <Footer />
        </>
    )
}

export default Generlchp