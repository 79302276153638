import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/s7.jpg'


const Maternity = () => {
    return (
        <>

            <div className='heading'>
                <h1>Home / Maternity Care </h1>
            </div>

            <div className='general'>
                <div className='general2'>
                    <h1>Maternity Care</h1>
                    <p>Maternal care refers to all aspects of antepartum, intrapartum, and postpartum care. Table 1 also refers to low-, moderate-, and high-risk care; defining what constitutes these levels of risk should be individualized by facilities and regions, with input from their obstetric care providers.</p>
                    <p>Maternal health care is essential, not only to the lives of mothers and babies but to the general welfare of society. Because they play a vital role in promoting maternal health, nurses have the opportunity to help address the challenges mothers face in accessing quality health care and leading healthier lives.</p>
                </div>
                <div className='general2'>
                    <img src={gal1} />
                </div>
            </div>


            <Footer />
        </>
    )
}

export default Maternity