import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/cancer.jpg'
const CancerSurgery = () => {
  return (

    <>

      <div className='heading'>
        <h1>Home / Cancer Surgery </h1>
      </div>
      <div className='general'>
        <div className='general2'>
          <h1>Cancer Surgery</h1>
          <p>it's possible, the goal of cancer surgery is to remove all of the cancer from the body. To do this, the surgeon uses cutting tools to remove the cancer and some healthy tissue around it. The surgeon may also remove some lymph nodes in the area. The lymph nodes are tested to see if they contain cancer cells.</p>

          <p> Many types of cancer are treated with surgery. Surgery works best for solid tumors that are contained in one area. It is a local treatment, meaning that it treats only the part of your body with the cancer. It is not used for leukemia (a type of blood cancer) or for cancers that have spread.</p>
        </div>
        <div className='general2'>
          <img src={gal1} />
        </div>
      </div>


      <Footer />
    </>

  )
}

export default CancerSurgery