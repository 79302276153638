import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/s7.jpg'


const Gynaecology = () => {
  return (
    <>

      <div className='heading'>
        <h1>Home / Gynaecology & Obstetrics </h1>
      </div>

      <div className='general'>
        <div className='general2'>
          <h1>Gynaecology & Obstetrics</h1>
          <p> <b>Gynaecology</b> - Gynaecology or gynecology (see American and British English spelling differences) is the area of medicine that involves the treatment of women's diseases, especially those of the reproductive organs. It is often paired with the field of obstetrics, forming the combined area of obstetrics and gynaecology (OB-GYN).</p>
          <p> <b>Obstetrics</b> A branch of medicine that specializes in the care of women during pregnancy and childbirth and in the diagnosis and treatment of diseases of the female reproductive organs. It also specializes in other women's health issues, such as menopause, hormone problems, contraception (birth control), and infertility.</p>
        </div>
        <div className='general2'>
          <img src={gal1} />
        </div>
      </div>


      <Footer />
    </>
  )
}

export default Gynaecology