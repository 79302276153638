import React from 'react'

const Header = () => {
    return (
        <div className='header'>
       
            <marquee>
            <p>AVICENNA HEALTH CARE & RESEARCH CENTRE  RESPONSE SYSTEM  –  24×7  Hospital Ambulance  /  Emergency Helpline No. : 7991587323</p>
            </marquee>
       
    </div>
    )
}

export default Header