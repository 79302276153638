import React from 'react'
import {Link} from 'react-router-dom';

const Treatment = () => {
    return (
        <>
            <br /><br />
           
            <center><h3>AVICENNA HOSPITAL TREATMENT</h3></center>
            <div className='tratment'>
                <div className='treat2'>
                    <h1>General Checkups, Psychiatry</h1>
                    <p>A general psychiatric evaluation is ordered when there's suspicion that you have a mental health condition. During the assessment, your medical and family history will be reviewed.</p>

                   <Link to='/Generlchp'> <button  className="btn btn-primary">Read More</button></Link>

                </div>
                <div className='treat2'>
                    <h1>General Surgery</h1>
                    <p>The branch of surgery that covers the main areas of surgical treatment. General surgeons treat diseases of the abdomen, breast, head and neck, blood vessels, and digestive tract. </p>
                    <Link to='/Generalsurgy'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Laparoscopic Surgery</h1>
                    <p>Overview. Laparoscopic surgery is a surgical technique in which short, narrow tubes (trochars) are inserted into the abdomen through small (less than one centimeter) incisions. </p>
                    <Link to='/LaparoscopicSurgery'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
            </div>

            <div className='tratment'>
                <div className='treat2'>
                    <h1>Cancer Surgery</h1>
                    <p>When it's possible, the goal of cancer surgery is to remove all of the cancer from the body. To do this, the surgeon uses cutting tools to remove the cancer and some healthy tissue around it. </p>
                    <Link to='/CancerSurgery'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Chemotherapy</h1>
                    <p>Chemotherapy is a drug treatment that uses powerful chemicals to kill fast-growing cells in your body. Chemotherapy is most often used to treat cancer, since cancer cells grow and multiply much more quickly than most cells in the body.</p>
                    <Link to='/Chemotherapy'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Gastroenterology</h1>
                    <p>Gastroenterology, medical specialty concerned with the digestive system and its diseases. Gastroenterologists diagnose and treat the diseases and disorders of the esophagus, stomach, intestines, liver, biliary tract, and pancreas. </p>
                    <Link to='/Gastroenterology'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
            </div>


            <div className='tratment'>
                <div className='treat2'>
                    <h1>Maternity Care</h1>
                    <p>Maternity care can be defined as 'the constellation of health services provided by a physician, nurse, midwife, hospital or birthing centre to a pregnant woman during pregnancy (prenatal care), delivery, and after delivery (postnatal care)' (Segen's Medical Dictionary, 2011). </p>
                    <Link to='/Maternity'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Gynaecology & Obstetrics</h1>
                    <p>Gynecology is the care of a woman's reproductive organs and health. Obstetrics involves the treatment of pregnant women, including the delivery of babies. Obstetrician gynecologists play a critical role in women's healthcare. </p>
                    <Link to='/Gynaecology'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Neurology</h1>
                    <p>Neurology, medical specialty concerned with the nervous system and its functional or organic disorders. Neurologists diagnose and treat diseases and disorders of the brain, spinal cord, and nerves. </p>
                    <Link to='/Neurology'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
            </div>

            <div className='tratment'>
                <div className='treat2'>
                    <h1>Orthopedics</h1>
                    <p>Orthopedics, medical specialty concerned with the preservation and restoration of function of the skeletal system and its associated structures, i.e., spinal and other bones, joints, and muscles. orthopedics.</p>
                    <Link to='/Orthopedics'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Urology, Nephrology</h1>
                    <p>Nephrology is the field of medicine that focuses on diagnosing and treating diseases that affect the kidneys. Like urologists, nephrologists receive extensive education and training in their field. </p>
                    <Link to='/Urology'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
                <div className='treat2'>
                    <h1>Ambulance Service</h1>
                    <p>They provide emergency medical care and transportation to patients in need of urgent medical attention. Ambulance is equipped with advanced medical equipment and trained personnel to provide necessary medical care on the way to the hospital.  </p>
                    <Link to='/Ambulance'> <button  className="btn btn-primary">Read More</button></Link>
                </div>
            </div>

        </>
    )
}

export default Treatment