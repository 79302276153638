import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/ambu.jpg'


const Ambulance = () => {
  return (
    <>

            <div className='heading'>
                <h1>Home / Ambulance Services </h1>
            </div>

            <div className='general'>
                <div className='general2'>
                    <h1>Ambulance </h1>
                    <p>  a vehicle that is equipped for transporting the injured or the sick. Etymology. from French ambulance "field hospital," from (hôpital) ambulant, literally "traveling hospital," derived from Latin ambulare "to walk" — related to amble. Word Origin.</p>
                    <p> The term ambulance comes from the Latin word "ambulare" as meaning "to walk or move about" which is a reference to early medical care where patients were moved by lifting or wheeling. The word originally meant a moving hospital, which follows an army in its movements.</p>
                </div>
                <div className='general2'>
                    <img src={gal1} />
                </div>
            </div>

            <Footer />
        </>
  )
}

export default Ambulance