import React, { useState } from 'react';



const Head = () => {
   
    return (
        <div className='head'>
            <div className='head2'>
                <p>Managing Director: 9555748835, 9415549685</p>
            </div>
            <div className='head2'>
                <p>Hospital Contact Number: 7991587323</p>
            </div>
            <div className='head2'>
                <p>628, S/K 002CC, Kukrail Bandha Road Sarvodya Nagar, Lucknow</p>
            </div>
            
          
        </div>
    );
};

export default Head;
