import React, { useState } from 'react';
import Carousal from './Carousal';
import About from './About';
import Treatment from './Treatment';
import Footer from './Footer';
import ImageSlider from './ImageSlider';
import Why from './Why';
import Avicenna from './Avicenna';

const Home = () => {


    return (
        <div className='main'>
            <Carousal />
            <About />
            <Why/>
            <Avicenna/>
            <Treatment />
            {/* <ImageSlider/> */}
            <Footer />
           
        </div>
    );
};

export default Home;
