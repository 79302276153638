import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/urology.jpg'

const Urology = () => {
    return (
        <>

            <div className='heading'>
                <h1>Home / Urology, Nephrology </h1>
            </div>

            <div className='general'>
                <div className='general2'>
                    <h1>Urology, Nephrology</h1>
                    <p> Urology is a part of health care that deals with diseases of the male and female urinary tract (kidneys, ureters, bladder and urethra). It also deals with the male organs that are able to make babies (penis, testes, scrotum, prostate, etc.).</p>
                    <p> Nephrology is the adult and pediatric study of the kidneys and its diseases. The nephrologist deals with the diagnosis and management of kidney disease. The kidneys are vital for maintaining normal fluid and electrolyte balance in the body.</p>
                </div>
                <div className='general2'>
                    <img src={gal1} />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Urology