import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/s3.jpg'
const LaparoscopicSurgery = () => {
  return (

    <>

      <div className='heading'>
        <h1>Home / Laparoscopic Surgery </h1>
      </div>

      <div className='general'>
        <div className='general2'>
          <h1>Laparoscopic Surgery</h1>
          <p>Laparoscopic surgery is a surgical technique in which short, narrow tubes (trochars) are inserted into the abdomen through small (less than one centimeter) incisions. Through these trochars, long, narrow instruments are inserted. The surgeon uses these instruments to manipulate, cut, and sew tissue.</p>

          <p> A laparoscopy can be used to diagnose conditions such as appendicitis, pelvic inflammatory disease, endometriosis and some cancers, such as liver cancer and ovarian cancer. It's also used for surgery to treat conditions, such as: removing organs such as the appendix or gallbladder.</p>
        </div>
        <div className='general2'>
          <img src={gal1} />
        </div>
      </div>

      <Footer />
    </>

  )
}

export default LaparoscopicSurgery