import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/s6.jpg'


const Gastroenterology = () => {
  return (


    <>

      <div className='heading'>
        <h1>Home / Gastroenterology </h1>
      </div>

      <div className='general'>
        <div className='general2'>
          <h1>Gastroenterology</h1>
          <p>Gastroenterology, medical specialty concerned with the digestive system and its diseases. Gastroenterologists diagnose and treat the diseases and disorders of the esophagus, stomach, intestines, liver, biliary tract, and pancreas.</p>
          <p>You should see a gastroenterologist if you have any symptoms of a digestive health disorder or if you need colon cancer screening. Often, seeing a gastroenterologist leads to more accurate detection of polyps and cancer, fewer complications from procedures and less time spent in the hospital.</p>
        </div>
        <div className='general2'>
          <img src={gal1} />
        </div>
      </div>



      <Footer />
    </>

  )
}

export default Gastroenterology