import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/s9.jpg'

const Neurology = () => {
  return (
    <>

    <div className='heading'>
        <h1>Home / Neurology </h1>
    </div>

    <div className='general'>
        <div className='general2'>
          <h1>Neurology</h1>
          <p> Neurology (from Greek: νεῦρον (neûron), "string, nerve" and the suffix -logia, "study of") is the branch of medicine dealing with the diagnosis and treatment of all categories of conditions and disease involving the nervous system, which comprises the brain, the spinal cord and the peripheral nerves.</p>
          <p> A neurologist is a medical doctor who diagnoses, treats and manages disorders of the brain and nervous system (brain, spinal cord and nerves). A neurologist knows the anatomy, function and conditions that affect your nerves and nervous system. Your nervous system is your body's command center.</p>
        </div>
        <div className='general2'>
          <img src={gal1} />
        </div>
      </div>


    <Footer />
</>
  )
}

export default Neurology