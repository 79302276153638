import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/ortho.jpg'

const Orthopedics = () => {
  return (
    <>

    <div className='heading'>
        <h1>Home / Orthopedics </h1>
    </div>

    <div className='general'>
        <div className='general2'>
          <h1>Orthopedics</h1>
          <p> Orthopedics, medical specialty concerned with the preservation and restoration of function of the skeletal system and its associated structures, i.e., spinal and other bones, joints, and muscles. orthopedics. See all media. Also called: orthopedic surgery</p>
          <p> In simpler terms, an orthopaedic surgeon helps you with diseases and conditions that affect your muscles, joints, bones, ligaments and tendons, and nerves.</p>
        </div>
        <div className='general2'>
          <img src={gal1} />
        </div>
      </div>


    <Footer />
</>
  )
}

export default Orthopedics