import React from 'react'

const Why = () => {
    return (
        <>
            <div className='why'>
                <h1>WHY CHOOSE US</h1>

                <div className='whys2'>

                    <div className='agency'>

                        <div className='agency2'>
                            <h1>24/7 Emergency Services</h1>
                            <p>In commerce and industry, 24/7 or 24-7 service (usually pronounced "twenty-four seven") is service that is available at any time and usually, every day. An alternate orthography for the numerical part includes 24×7 (usually pronounced "twenty-four by seven"). </p>
                        </div>
                        <div className='agency2'>
                            <h1>24/7 ICU Care</h1>
                            <p>The patient's condition can be quickly and accurately diagnosed thanks to the availability of all services around-the-clock. Modern monitoring equipment, ventilators, and oxygen delivery systems are available in every ICU bed.</p>
                        </div>

                    </div>

                    <div className='agency'>

                        <div className='agency2'>
                            <h1>Safe Treatment</h1>
                            <p> the action or way of treating a patient or a condition medically or surgically : management and care to prevent, cure, ameliorate, or slow progression of a medical condition. treatment of accident victims.</p>
                        </div>
                        <div className='agency2'>
                            <h1>Specialist Team Of Doctors</h1>
                            <p>A medical specialist is a doctor whose work is centered on one field of medicine, e.g. internal medicine, pediatrics, surgery, orthopedics, urology, ENT, ophthalmology, OB-GYN, gastro-enterology, rheumatology, geriatrics, cardiology, pulmonology, neurology, psychiatry.</p>
                        </div>

                    </div>

                </div>


            </div>

        </>
    )
}

export default Why