import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/s5.jpg'
const Chemotherapy = () => {
  return (
    <>

      <div className='heading'>
        <h1>Home / Chemotherapy </h1>
      </div>
      <div className='general'>
        <div className='general2'>
          <h1>Chemotherapy</h1>
          <p>Chemotherapy is a drug treatment that uses powerful chemicals to kill fast-growing cells in your body. Chemotherapy is most often used to treat cancer, since cancer cells grow and multiply much more quickly than most cells in the body.</p>
          <p> The Median Duration of Response tells you how long your cancer can be expected to respond to the chemotherapy, before the cancer starts growing again. For most cancers where palliative chemotherapy is used, this number ranges from 3-12 months. The longer the response, the longer you can expect to live.</p>
        </div>
        <div className='general2'>
          <img src={gal1} />
        </div>
      </div>


      <Footer />
    </>
  )
}

export default Chemotherapy