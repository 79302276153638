import React from 'react'
import Footer from '../component/Footer'
import gal1 from '../component/img/s2.jpg'
const Generalsurgy = () => {
    return (
        <>

            <div className='heading'>
                <h1>Home / General Surgery </h1>
            </div>

            <div className='general'>
                <div className='general2'>
                    <h1>General Surgery</h1>
                    <p>The branch of surgery that covers the main areas of surgical treatment. General surgeons treat diseases of the abdomen, breast, head and neck, blood vessels, and digestive tract. They also manage care of patients who have been injured or who have deformities or other conditions that need surgery.</p>

                    <p>General surgeons may also treat ailments such as burns, cancer, head injuries and neck injuries. They can also perform transplants or vascular surgery. In more rural locations, where specialty surgeons are less available, general surgeons may perform a wider range of surgeries.</p>
                </div>
                <div className='general2'>
                    <img src={gal1} />
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Generalsurgy