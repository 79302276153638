import React from 'react'
import Footer from '../component/Footer'
import gal1 from "../component/img/gal1.jpg"
import gal2 from "../component/img/gal2.jpg"
import gal3 from "../component/img/gal3.jpg"
import gal4 from "../component/img/gal4.jpg"
import gal5 from "../component/img/gal5.jpg"
import gal6 from "../component/img/gal6.jpg"

const Gallery = () => {
  return (
    <>
      <div className='heading'>
        <h1>Home / Gallery </h1>
      </div>

      <div className='gallery'>

        <div className='gal'>
          <img src={gal4} />
        </div>
        <div className='gal'>
          <img src={gal2} />
        </div>
        <div className='gal'>
          <img src={gal3} />
        </div>
       

      </div>

      <div className='gallery'>

        <div className='gal'>
          <img src={gal4} />
        </div>
        <div className='gal'>
          <img src={gal1} />
        </div>
        <div className='gal'>
          <img src={gal6} />
        </div>
       

      </div>

      <Footer />
    </>
  )
}

export default Gallery